import React, { FunctionComponent } from "react";

import styles from "./BasicNavigation.module.scss";
import { Link } from "react-router-dom";

const BasicNavigation: FunctionComponent = () => {
  return (
    <nav className={styles.navigation}>
      <div>
        <a href="/">Home</a>
      </div>
      <div>
        <Link to="/">Blog</Link>
      </div>
    </nav>
  );
};

export default BasicNavigation;
