import { FunctionComponent } from "react";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import rehypeSlug from "rehype-slug";
import rehypeAutolinkHeadings from "rehype-autolink-headings";
import remarkGemoji from "remark-gemoji";
import remarkGFM from "remark-gfm";

import { MarkdownCodeBlock } from "./MarkdownCodeBlock";

import "../Markdownbasestyle.scss";

export const ReactMarkdownPreDefined: FunctionComponent<{
  content: string;
  classNames?: string;
}> = ({ content, classNames }) => {
  classNames += " ReactMarkdownPreDefinedBlock";

  return (
    <ReactMarkdown
      skipHtml={true}
      className={classNames ?? ""}
      components={{
        code({ node, inline, className, children, ...props }) {
          return (
            <MarkdownCodeBlock
              inline={inline}
              className={className}
              children={children}
            />
          );
        },
      }}
      rehypePlugins={[rehypeSlug, rehypeAutolinkHeadings, rehypeKatex]}
      remarkPlugins={[remarkGFM, remarkGemoji, remarkMath]}
    >
      {content}
    </ReactMarkdown>
  );
};
