import {
  FILTER_DATA,
  FILTER_DATA_LOADING,
  FILTER_DATA_FAILED,
} from "../sagas/types";

const initialState = {
  loading: false,
  error: "",
  data: {},
};

export function filterReducer(state = initialState, action: any) {
  switch (action.type) {
    case FILTER_DATA:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FILTER_DATA_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FILTER_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
