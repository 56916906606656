import React from "react";
import styles from "./Footer.module.scss";
import { useSelector } from "react-redux";
import { Base64 } from "js-base64";
import { ReduxState } from "../../../types/state";

const Footer: React.FunctionComponent = () => {
  const year = new Date().getFullYear();
  const isAuth = useSelector((state: ReduxState) => state.user.isAuthenticated);

  return (
    <footer className={styles.footer}>
      <div className="flex-container">
        <p>&copy; by Joel Mühlena {year}</p>
        <p className={styles.links}>
          <a href="https://joel.muehlena.de/datenschutz">Datenschutz</a>
          <a href="https://joel.muehlena.de/impressum">Impressum</a>
          {!isAuth && (
            <a
              href={`http://joel.muehlena.de/login?callbackUrl=${Base64.encode(
                "https://blog.joel.muehlena.de"
              )}`}
            >
              Login
            </a>
          )}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
