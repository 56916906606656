import { combineReducers } from "redux";
import blogReducer from "./blogReducer";
import { authorReducer } from "./authorReducer";
import { userReducer } from "./userReducer";
import { filterReducer } from "./filterReducer";

const rootReducer = combineReducers({
  blogPosts: blogReducer,
  blogAuthors: authorReducer,
  user: userReducer,
  filterOptions: filterReducer,
});

export default rootReducer;
