import { useSelector } from "react-redux";
import { ReduxState } from "../types/state";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";

export const useRoleVerification = (permission: string) => {
  const user = useSelector((state: ReduxState) => state.user);

  const [data, setData] = useState(true);

  const getVerification = useCallback(async () => {
    try {
      const result = await axios.post(
        "https://auth.joel.muehlena.de/auth/verifyRole",
        { permission, roleName: user.data.roles }
      );

      if (result.status === 200) {
        setData(true);
      }
    } catch (error) {
      setData(false);
    }
  }, [permission, user.data.roles]);

  useEffect(() => {
    if (user.isAuthenticated) {
      getVerification();
    }
  }, [user, getVerification]);

  return data;
};
