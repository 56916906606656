import React, { useEffect, useState, FunctionComponent } from "react";
import { Link, useLocation } from "react-router-dom";
import { Base64 } from "js-base64";

import styles from "./NotFound404.module.scss";

const NotFound404: FunctionComponent = () => {
  const [oldTitle, setOldtitle] = useState(document.title);
  const location = useLocation();
  const message = new URLSearchParams(location.search).get("message");

  useEffect(() => {
    setOldtitle(document.title);
    document.title = "404 Not found";
    return () => {
      document.title = oldTitle;
    };
  }, [oldTitle]);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.content}>
        <h2>404</h2>
        <h3>Ooops! The page or ressource you requested doesn't exist.</h3>
        {message !== null && <p>Error message: {Base64.decode(message)}</p>}
        <p>
          Please make sure that you have written everything correctly. If you
          entered the url manually, try to access the desired page via a link in
          the blog.
        </p>
        <Link to="/">Go Back to the blog</Link>
      </div>
    </div>
  );
};

export default NotFound404;
