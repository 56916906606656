import React, {
  useState,
  TouchEvent,
  useRef,
  MutableRefObject,
  Dispatch,
  SetStateAction,
  useEffect,
  MouseEvent,
  useCallback,
} from "react";

import styles from "./FilterMenu.module.scss";
import "./FilterMenu_transitions.scss";
import CloseIcon from "../util/CloseIcon";
import { useNavigate, useLocation } from "react-router-dom";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import MongoDate from "../MongoDate";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { GET_BLOG_FILTER_REQUEST } from "../../sagas/types";
import { ReduxState } from "../../types/state";
import { isTouchDevice } from "../../util/istouch";

type DateFilter = { from: Date | undefined; to: Date | undefined };

enum FilterType {
  AUTHOR = "AUTHOR",
  FROM = "FROM",
  TO = "TO",
  TAG = "TAG",
  CATEGORY = "CATEGORY",
}

const FilterMenu: React.FunctionComponent<{
  setFilter: Dispatch<SetStateAction<boolean>>;
}> = ({ setFilter }) => {
  const [authorFilter, setAuthorFilter] = useState([] as Array<string>);
  const [dateFilter, setDateFilter] = useState({
    from: undefined,
    to: undefined,
  } as DateFilter);
  const [tagFilter, setTagFilter] = useState([] as Array<string>);
  const [categoryFilter, setCategoryFilter] = useState([] as Array<string>);
  const [getSwipe, setSwipe] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const filterOptions = useSelector(
    (state: ReduxState) => state.filterOptions,
    shallowEqual
  );
  const dispatch = useDispatch();
  const filterMenu: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const allFilterContent: MutableRefObject<HTMLDivElement | null> =
    useRef(null);

  useEffect(() => {
    dispatch({ type: GET_BLOG_FILTER_REQUEST });
  }, [dispatch]);

  useEffect(() => {
    const datePicker = document.getElementsByClassName(
      "react-datepicker__input-container"
    );
    for (let i = 0; i < datePicker.length; i++) {
      datePicker[i].childNodes.forEach((item) => {
        (item as any).setAttribute("readOnly", true);
      });
    }
  }, [filterOptions]);

  const isFilterSet = useCallback((): boolean => {
    let isSet = false;

    if (authorFilter.length > 0) isSet = true;
    if (categoryFilter.length > 0) isSet = true;
    if (tagFilter.length > 0) isSet = true;
    if (dateFilter.from !== undefined || dateFilter.to !== undefined)
      isSet = true;

    return isSet;
  }, [authorFilter, categoryFilter, tagFilter, dateFilter]);

  useEffect(() => {
    if (
      location.search
        .replace("?", "")
        .split("&")
        .find((i) => i.startsWith("filter"))
        ?.replace("filter=", "") === "true"
    ) {
      let filterValues = location.search.replace("?", "").split("&");
      filterValues.splice(0, 1);

      let from: Date | undefined = undefined;
      let to: Date | undefined = undefined;

      for (let i = 0; i < filterValues.length; i++) {
        let item = filterValues[i];
        if (item.startsWith("author")) {
          let s = new URLSearchParams(location.search).get("author");
          let items = s!.split(";");
          setAuthorFilter(items);
        } else if (item.startsWith("categories")) {
          let s = new URLSearchParams(location.search).get("categories");
          let items = s!.split(";");
          setCategoryFilter(items);
        } else if (item.startsWith("tags")) {
          let s = new URLSearchParams(location.search).get("tags");
          let items = s!.split(";");
          setTagFilter(items);
        } else if (item.startsWith("from")) {
          let s = new URLSearchParams(location.search).get("from");
          from = new Date(s!);
        } else if (item.startsWith("to")) {
          let s = new URLSearchParams(location.search).get("to");
          to = new Date(s!);
        }
      }

      setDateFilter({ from, to });
    }
  }, [location.search, filterOptions]);

  const createSearchUrl = (): string => {
    let url = "?filter=true";

    if (authorFilter.length > 0) {
      let s = "";
      authorFilter.forEach((item, i) => {
        s += item + (i === authorFilter.length - 1 ? "" : ";");
      });
      url += "&author=" + s;
    }

    if (tagFilter.length > 0) {
      let s = "";
      tagFilter.forEach((item, i) => {
        s += item + (i === tagFilter.length - 1 ? "" : ";");
      });
      url += "&tags=" + s;
    }

    if (categoryFilter.length > 0) {
      let s = "";
      categoryFilter.forEach((item, i) => {
        s += item + (i === categoryFilter.length - 1 ? "" : ";");
      });
      url += "&categories=" + s;
    }

    if (dateFilter.from !== undefined) {
      url += "&from=" + dateFilter.from.toISOString();
    }

    if (dateFilter.to !== undefined) {
      url += "&to=" + dateFilter.to.toISOString();
    }

    if (!isFilterSet()) {
      url = "/";
    }

    return url;
  };

  const startSwipeDown = (e: TouchEvent<HTMLDivElement>) => {
    //e.preventDefault();
    setSwipe(e.changedTouches[0].pageY);
    filterMenu.current!.style.transform = `translateY(1)`;
  };

  const moveSwipeDown = (e: TouchEvent<HTMLDivElement>) => {
    //e.preventDefault();
    let changeY = e.changedTouches[0].pageY;

    var style = window.getComputedStyle(filterMenu.current!);
    var matrix = new WebKitCSSMatrix(style.webkitTransform);

    if (matrix.m42 + (changeY - getSwipe) >= 0) {
      filterMenu.current!.style.transform = `translateY(${
        matrix.m42 + (changeY - getSwipe)
      }px)`;
      setSwipe(changeY);
    }
  };

  const endSwipeDown = (e: TouchEvent<HTMLDivElement>) => {
    //e.preventDefault();
    var style = window.getComputedStyle(filterMenu.current!);
    var matrix = new WebKitCSSMatrix(style.webkitTransform);

    if (matrix.m42 > 300) {
      navigate(createSearchUrl());
      document.getElementsByTagName("body")[0]!.style.overflowY = "visible";
      document
        .getElementsByClassName("filterButton--opened")[0]!
        .classList.remove("filterButton--opened");

      filterMenu.current!.style.transition = `transform 300ms`;
      filterMenu.current!.style.transform = `translateY(1000px)`;

      setFilter(false);
      setSwipe(0);
      setTimeout(() => {
        filterMenu.current!.style.transition = `none`;
      }, 300);
    } else {
      filterMenu.current!.style.transition = `transform 0.2s`;
      filterMenu.current!.style.transform = `translateY(0)`;
      setSwipe(0);
      setTimeout(() => {
        filterMenu.current!.style.transition = `none`;
      }, 200);
    }
  };

  const cancelSwipeDown = (e: TouchEvent<HTMLDivElement>) => {
    //e.preventDefault();
    setSwipe(0);
    filterMenu.current!.style.transition = `transform 0.2s`;
    filterMenu.current!.style.transform = `translateY(0)`;
    setTimeout(() => {
      filterMenu.current!.style.transition = `none`;
    }, 200);
  };

  const removeAllFilters = () => {
    setAuthorFilter([]);
    setCategoryFilter([]);
    setTagFilter([]);
    setDateFilter({ from: undefined, to: undefined });
  };

  const setFilterValue = (
    e: MouseEvent<HTMLButtonElement> | null,
    filterType: FilterType,
    date: Date | null = null
  ) => {
    if (filterType !== FilterType.FROM && filterType !== FilterType.TO) {
      const value: string = e!.currentTarget.innerText;
      let oldVals: Array<string>;
      let newArray: Array<string>;

      switch (filterType) {
        case FilterType.AUTHOR:
          oldVals = authorFilter;
          newArray = oldVals.concat(value);
          setAuthorFilter(newArray);
          break;
        case FilterType.CATEGORY:
          oldVals = categoryFilter;
          newArray = oldVals.concat(value);
          setCategoryFilter(newArray);
          break;
        case FilterType.TAG:
          oldVals = tagFilter;
          newArray = oldVals.concat(value);
          setTagFilter(newArray);
          break;
      }
    } else {
      if (filterType === FilterType.FROM) {
        setDateFilter({ ...dateFilter, from: date! });
      } else {
        setDateFilter({ ...dateFilter, to: date! });
      }
    }
  };

  const removeFilterValue = (
    e: MouseEvent<HTMLDivElement | HTMLSpanElement>
  ) => {
    const filterType = e.currentTarget.dataset.type;

    if (filterType !== FilterType.FROM && filterType !== FilterType.TO) {
      console.log(e.currentTarget);
      const value = e.currentTarget.dataset.content!;
      console.log(value);
      let newArray: Array<string>;
      let valueIndex: number;

      switch (filterType) {
        case FilterType.AUTHOR:
          newArray = [...authorFilter];
          valueIndex = newArray.indexOf(value);
          newArray.splice(valueIndex, 1);
          setAuthorFilter(newArray);
          break;
        case FilterType.CATEGORY:
          newArray = [...categoryFilter];
          valueIndex = newArray.indexOf(value);
          newArray.splice(valueIndex, 1);
          setCategoryFilter(newArray);
          break;
        case FilterType.TAG:
          newArray = [...tagFilter];
          valueIndex = newArray.indexOf(value);
          newArray.splice(valueIndex, 1);
          setTagFilter(newArray);
          break;
      }
    } else {
      if (filterType === FilterType.FROM) {
        setDateFilter({ ...dateFilter, from: undefined });
      } else {
        setDateFilter({ ...dateFilter, to: undefined });
      }
    }
  };

  const cancelFilters = () => {
    removeAllFilters();
    document.getElementsByTagName("body")[0]!.style.overflowY = "visible";
    document
      .getElementsByClassName("filterButton--opened")[0]!
      .classList.remove("filterButton--opened");

    setFilter(false);
  };

  const applyFilters = () => {
    navigate(createSearchUrl());

    document.getElementsByTagName("body")[0]!.style.overflowY = "visible";
    document
      .getElementsByClassName("filterButton--opened")[0]!
      .classList.remove("filterButton--opened");

    setFilter(false);
  };

  return (
    <div className={styles.filterMenu} ref={filterMenu}>
      {isTouchDevice() && (
        <div
          className={styles.swipeDown}
          onTouchStart={startSwipeDown}
          onTouchMove={moveSwipeDown}
          onTouchEnd={endSwipeDown}
          onTouchCancel={cancelSwipeDown}
        >
          <i className="las la-angle-down" />
        </div>
      )}
      <div className={styles.applyFilter}>
        <button onClick={cancelFilters}>Cancel</button>
        <button onClick={applyFilters}>Apply</button>
      </div>
      <div className={styles.content_wrapper}>
        <div className={styles.overflow_container}>
          <div className={styles.allFilterContent} ref={allFilterContent}>
            <div className={styles.activeFilter}>
              <h2>
                Active filters
                {isFilterSet() && (
                  <span
                    onClick={removeAllFilters}
                    className={styles.removeAllFilters}
                  >
                    Remove all
                  </span>
                )}
              </h2>
              {authorFilter.map((i) => (
                <span
                  className={styles.item}
                  data-type={FilterType.AUTHOR}
                  data-content={i}
                  key={i}
                  onClick={removeFilterValue}
                >
                  {i}{" "}
                  <CloseIcon
                    data-type={FilterType.AUTHOR}
                    data-content={i}
                    onclick={removeFilterValue}
                  />
                </span>
              ))}
              {dateFilter.from !== undefined && (
                <span
                  className={styles.item}
                  onClick={removeFilterValue}
                  data-type={FilterType.FROM}
                >
                  From: <MongoDate date={dateFilter.from} />
                  <CloseIcon
                    data-type={FilterType.FROM}
                    onclick={removeFilterValue}
                  />
                </span>
              )}
              {dateFilter.to !== undefined && (
                <span
                  className={styles.item}
                  onClick={removeFilterValue}
                  data-type={FilterType.TO}
                >
                  To: <MongoDate date={dateFilter.to} />
                  <CloseIcon
                    data-type={FilterType.TO}
                    onclick={removeFilterValue}
                  />
                </span>
              )}
              {tagFilter.map((i) => (
                <span
                  key={i}
                  className={styles.item}
                  onClick={removeFilterValue}
                  data-type={FilterType.TAG}
                  data-content={i}
                >
                  {i}{" "}
                  <CloseIcon
                    data-type={FilterType.TAG}
                    data-content={i}
                    onclick={removeFilterValue}
                  />
                </span>
              ))}
              {categoryFilter.map((i) => (
                <span
                  key={i}
                  className={styles.item}
                  onClick={removeFilterValue}
                  data-content={i}
                  data-type={FilterType.CATEGORY}
                >
                  {i}{" "}
                  <CloseIcon
                    data-type={FilterType.CATEGORY}
                    data-content={i}
                    onclick={removeFilterValue}
                  />
                </span>
              ))}
            </div>
            <div className={styles.filterGroupWrapper}>
              <div className={styles.filterGroup}>
                <h2>Authors</h2>
                {filterOptions.data.authors &&
                  filterOptions.data.authors.map(
                    (author: string, i: number) => (
                      <button
                        data-content={author}
                        key={i}
                        onClick={(e) => {
                          setFilterValue(e, FilterType.AUTHOR);
                        }}
                        disabled={authorFilter.includes(author)}
                        className={styles.filterButton}
                      >
                        {author}
                      </button>
                    )
                  )}
              </div>
              <div
                className={[
                  styles.filterGroup,
                  styles.filterGroup_dateFilter,
                ].join(" ")}
              >
                <h2>Date</h2>
                <div className={styles.filterGroup_dateFilter_dateWrapper}>
                  <div className={styles.filterGroup_dateFilter_from}>
                    <p>From:</p>
                    <ReactDatePicker
                      selected={
                        dateFilter.from !== undefined
                          ? dateFilter.from
                          : new Date()
                      }
                      dateFormat="MMMM dd, yyyy"
                      maxDate={new Date()}
                      onChange={(d) => {
                        setFilterValue(null, FilterType.FROM, d as Date);
                      }}
                    />
                  </div>
                  <div className={styles.filterGroup_dateFilter_to}>
                    <p>To:</p>
                    <ReactDatePicker
                      selected={
                        dateFilter.to !== undefined ? dateFilter.to : new Date()
                      }
                      dateFormat="MMMM dd, yyyy"
                      maxDate={new Date()}
                      onChange={(d) => {
                        setFilterValue(null, FilterType.TO, d as Date);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.filterGroup}>
                <h2>Tags</h2>
                {filterOptions.data.tags &&
                  filterOptions.data.tags.map((tag: string, i: number) => (
                    <button
                      data-content={tag}
                      key={i}
                      onClick={(e) => {
                        setFilterValue(e, FilterType.TAG);
                      }}
                      disabled={tagFilter.includes(tag)}
                      className={styles.filterButton}
                    >
                      {tag}
                    </button>
                  ))}
              </div>
              <div className={styles.filterGroup}>
                <h2>Categories</h2>
                {filterOptions.data.categories &&
                  filterOptions.data.categories.map(
                    (category: string, i: number) => (
                      <button
                        data-content={category}
                        key={i}
                        onClick={(e) => {
                          setFilterValue(e, FilterType.CATEGORY);
                        }}
                        disabled={categoryFilter.includes(category)}
                        className={styles.filterButton}
                      >
                        {category}
                      </button>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterMenu;
