import React, { SetStateAction, Dispatch, useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Filter from "./Filter";

import "./subNav.scss";
import { ReduxState } from "../../../types/state";

const SubNavigation: React.FunctionComponent<{
  setFilter: Dispatch<SetStateAction<boolean>>;
}> = ({ setFilter }) => {
  const userInfo = useSelector((state: ReduxState) => state.user, shallowEqual);
  const [isMobileWidth, setMobileWidth] = useState(false);
  const [isMobileHeight, setMobileHeight] = useState(false);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize, false);
    return () => {
      window.removeEventListener("resize", handleResize, false);
    };
  }, []);

  const handleResize = () => {
    let widht = window.innerWidth;
    let height = window.innerHeight;

    if (widht <= 600) {
      setMobileWidth(true);
    } else {
      setMobileWidth(false);
    }

    if (height <= 625) {
      setMobileHeight(true);
    } else {
      setMobileHeight(false);
    }
  };

  return (
    <div className="subNav-btn-wrapper">
      {isMobileHeight || isMobileWidth || userInfo.isAuthenticated ? (
        <Filter single={true} setFilter={setFilter} />
      ) : (
        <Filter single={true} setFilter={setFilter} />
      )}
    </div>
  );
};

export default SubNavigation;
