import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";

export interface WithRouterProps {
  location: Location;
  navigate: NavigateFunction;
}

export const withRouter = (Component: any) => {
  const Wrapper = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();

    return <Component location={location} navigate={navigate} {...props} />;
  };

  return Wrapper;
};
