import { all } from "redux-saga/effects";

import {
  watchPostFetch,
  watchFilterFetch,
  watchCreateNewPost,
  watchAuthorFetch,
  watchDeletePost,
  watchUpdatePost,
} from "./blogSagas";
import { watchSetUser } from "./userSagas";

export default function* rootSaga() {
  yield all([
    watchPostFetch(),
    watchSetUser(),
    watchFilterFetch(),
    watchCreateNewPost(),
    watchDeletePost(),
    watchAuthorFetch(),
    watchUpdatePost(),
  ]);
}
