import axios from "axios";

const setAuthToken = (token: string) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

//renamed file use this comment ro track in git
export default setAuthToken;
