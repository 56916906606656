import React, { MouseEvent } from "react";

import styles from "./Util.module.scss";

const CloseIcon: React.FunctionComponent<{
  onclick: (e: MouseEvent<HTMLDivElement>) => void;
}> = ({ onclick, ...props }) => {
  return (
    <div className={styles.closeIcon} onClick={onclick} {...props}>
      <i className="las la-times"></i>
    </div>
  );
};

export default CloseIcon;
