import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { ReduxState } from "../../types/state";
import { useLocation, Link, Navigate } from "react-router-dom";
import { Base64 } from "js-base64";

import styles from "./NoAccess403.module.scss";

function NoAccess403() {
  const isAuth = useSelector((state: ReduxState) => state.user, shallowEqual);
  const location = useLocation();
  const callback = new URLSearchParams(location.search).get("callback");
  const customText = new URLSearchParams(location.search).get("message");
  const redirect = new URLSearchParams(location.search).get("redirect");

  const [oldTitle, setOldtitle] = useState(document.title);

  useEffect(() => {
    setOldtitle(document.title);
    document.title = "403 Not permitted";
    return () => {
      document.title = oldTitle;
    };
  }, [oldTitle]);

  if (isAuth.isAuthenticated && callback !== null && redirect === "true") {
    return <Navigate to={Base64.decode(callback)} />;
  } else {
    return (
      <div className={styles.pageWrapper}>
        <div className={styles.content}>
          <h2>403</h2>
          <h3>
            Ooops! You don't have enough permissions to enter the requested
            page.
          </h3>
          {customText && <p>{Base64.decode(customText)}</p>}
          <p>
            Please{" "}
            <a
              href={`https://joel.muehlena.de/login?callbackUrl=${Base64.encode(
                "https://blog.joel.muehlena.de"
              )}`}
            >
              Login
            </a>{" "}
            or ask an admin for the right permission.
          </p>
          <Link to="/">Go Back to the blog</Link>
        </div>
      </div>
    );
  }
}

export default NoAccess403;
