import React, { FunctionComponent, useState } from "react";
import Header from "./Layout/Header/Header";
import Footer from "./Layout/Footer/Footer";
import { CSSTransition } from "react-transition-group";
import SubNavigation from "./Layout/Sub navigation/SubNavigation";
import FilterMenu from "./Filter/FilterMenu";

export const UserContentPage: FunctionComponent<{
  content: JSX.Element;
}> = ({ content: Content }) => {
  const [filterIn, setFilterIn] = useState(false);

  return (
    <>
      <Header /> <SubNavigation setFilter={setFilterIn} />
      <CSSTransition
        in={filterIn}
        unmountOnExit={true}
        timeout={400}
        classNames="filter-menu-trans"
      >
        <FilterMenu setFilter={setFilterIn} />
      </CSSTransition>
      {Content}
      <Footer />
    </>
  );
};
