import React, { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { Base64 } from "js-base64";
import { Author } from "../../types/user";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { ReduxState } from "../../types/state";
import isEmpty from "../../util/isEmpty";
import { GET_AUTHORS_REQUEST, GET_BLOG_POSTS_REQUEST } from "../../sagas/types";
import { BlogPost } from "../../types/blogPost";

import styles from "./AuthorPage.module.scss";
import PostPreviewAuthor from "../PostPreviewAuthor";
import BasicNavigation from "../BasicNavigation";

const AuthorPage: React.FunctionComponent = () => {
  const { authorId } = useParams();
  const dispatch = useDispatch();

  const authors = useSelector(
    (state: ReduxState) => state.blogAuthors,
    shallowEqual
  );

  const blogPosts = useSelector(
    (state: ReduxState) => state.blogPosts,
    shallowEqual
  );

  const [userPosts, setUserPosts] = useState<BlogPost[]>([]);
  const [author, setAuthor] = useState<Author | undefined>(undefined);

  useEffect(() => {
    let authorF = authors.allAuthors.find((a: Author) => a._id === authorId);

    if (!isEmpty(authors.author)) {
      setAuthor(!authors.isLoading ? (authors.author as any) : undefined);
    } else {
      if (
        authorF === undefined &&
        !authors.isLoading &&
        isEmpty(authors.error)
      ) {
        dispatch({
          type: GET_AUTHORS_REQUEST,
          payload: { id: authorId },
        });
      } else {
        setAuthor(!authors.isLoading ? (authorF as any) : undefined);
      }
    }
  }, [dispatch, authors, author, authorId]);

  useEffect(() => {
    if (!blogPosts.isLoading && blogPosts.allPosts.length > 0) {
      let blogPostsF = blogPosts.allPosts.filter(
        (post: BlogPost) => post.authorId === authorId
      );

      if (blogPostsF.length > 0 && userPosts.length <= 0) {
        setUserPosts(blogPostsF);
      }
    } else {
      dispatch({ type: GET_BLOG_POSTS_REQUEST });
    }
  }, [dispatch, blogPosts, userPosts, authorId]);

  return (
    <div>
      {authors.isLoading || author === undefined ? (
        <>
          {authors.error?.message ? (
            <Navigate
              to={`/blog/404?message=${Base64.encode(authors.error.message)}`}
            />
          ) : (
            <div>Loading...</div>
          )}
        </>
      ) : (
        <>
          <BasicNavigation />
          <div className={styles.authorContent}>
            <h2 className={styles.authorName}>
              {author.firstName} {author.lastName}
            </h2>
            <img
              alt={`${author.firstName} ${author.lastName}`}
              src={author.avatar}
              height="150"
              className={styles.authorPicture}
            />
            <p className={styles.authorBio}>
              {author.bio.length > 0
                ? author.bio
                : "This author doesn't have a bio"}
            </p>
            <div className={styles.socialLinks}>SOON</div>
            <div className={styles.authorPosts}>
              <hr />
              {userPosts.length > 0 ? (
                userPosts.map((post) => (
                  <PostPreviewAuthor
                    key={post._id}
                    author={author}
                    post={post}
                  />
                ))
              ) : (
                <p>This author has no posts yet</p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AuthorPage;
