import React from "react";
import { BlogPost } from "../types/blogPost";
import MongoDate from "./MongoDate";
import { isDateEqual } from "../util/isDateEqual";
import { useNavigate } from "react-router-dom";

import styles from "./PostPreviewAuthor.module.scss";
import { Author } from "../types/user";

const PostPreviewAuthor: React.FunctionComponent<{
  post: BlogPost;
  author: Author;
}> = ({ post, author }) => {
  const navigate = useNavigate();

  const openPost = () => {
    navigate(`/article/${post._id}`);
  };

  return (
    <div className={styles.blogPostPreview}>
      <div className={styles.heading}>
        <img
          src={author.avatar}
          height="100"
          className={styles.authorAvatar}
          alt={`Avatar of ${post.authorName}`}
        />
        <div>
          <p className={styles.authorName}>
            {post.authorName} <span> in {post.category}</span>
          </p>
          <span className={styles.dateContainer}>
            {!isDateEqual(
              new Date(post.createdAt),
              new Date(post.updatedAt)
            ) ? (
              <MongoDate date={post.updatedAt} />
            ) : (
              <MongoDate date={post.createdAt} />
            )}
          </span>
        </div>
      </div>

      <div onClick={openPost} className={styles.openPostDiv}>
        <h2 className={styles.title}>{post.title}</h2>
        <p className={styles.description}>{post.description}</p>
      </div>
    </div>
  );
};

export default PostPreviewAuthor;
