import { SET_USER, SET_USER_LOADING } from "../sagas/types";
import isEmpty from "../util/isEmpty";

const initialState = {
  isLoading: true,
  isAuthenticated: false,
  data: {},
};

export function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        data: action.payload,
        isLoading: false,
      };
    case SET_USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
}
