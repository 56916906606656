import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { GET_BLOG_POSTS_REQUEST, GET_AUTHORS_REQUEST } from "../../sagas/types";
import { BlogPost } from "../../types/blogPost";
import PostPreview from "../PostPreview";
import { ReduxState } from "../../types/state";

import styles from "./AllPosts.module.scss";

const AllPosts: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const blogPosts = useSelector(
    (state: ReduxState) => state.blogPosts,
    shallowEqual
  );

  useEffect(() => {
    let filterParams = new URLSearchParams(location.search.replace("?", ""));

    if (
      filterParams.get("filter") === null ||
      filterParams.get("filter") !== "true"
    ) {
      dispatch({ type: GET_BLOG_POSTS_REQUEST });
    } else {
      let filterObject: any = {};

      for (let [key, value] of filterParams.entries()) {
        if (key !== "filter") filterObject[key] = value;
      }

      dispatch({
        type: GET_BLOG_POSTS_REQUEST,
        payload: { filter: filterObject },
      });
    }
  }, [location, dispatch]);

  useEffect(() => {
    dispatch({ type: GET_AUTHORS_REQUEST });
  }, [dispatch]);

  return (
    <div className={styles.jmBlog}>
      {blogPosts.isLoading && <p>Loading...</p>}
      {blogPosts.error.noPostFound && <p>{blogPosts.error.noPostFound}</p>}
      {!blogPosts.isLoading &&
        !blogPosts.error.noPostFound &&
        blogPosts.allPosts.length === 0 && <p>There are no posts yet</p>}
      {blogPosts.allPosts.map((post: BlogPost) => (
        <PostPreview post={post} key={post._id} />
      ))}
    </div>
  );
};

export default AllPosts;
