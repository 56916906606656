import React from "react";
import { getMonthString } from "../util/getMonthString";

const MongoDate: React.FunctionComponent<{ date: Date }> = ({ date }) => {
  let da = new Date(date);
  let day = da.getDate();
  let month = getMonthString(da.getMonth() + 1);
  let year = da.getFullYear();

  return (
    <span className="jm-postDate">
      {month} {day}, {year}
    </span>
  );
};

export default MongoDate;
