import {
  GET_BLOG_POSTS,
  CREATE_NEW_POST_FAILED,
  CREATE_NEW_POST_SUCCEDEED,
  CREATE_NEW_POST,
  GET_AUTHORS,
  GET_BLOG_POSTS_FAILED,
  BLOG_POSTS_LOADING,
  DELETE_BLOG_POST_FAILED,
  DELETE_BLOG_POST_SUCCEEDED,
  UPDATE_POST,
  UPDATE_POST_FAILED,
  UPDATE_POST_SUCCEDEED,
} from "../sagas/types";

const initialState = {
  isLoading: false,
  allPosts: [],
  error: {},
  author: {},
  allAuthors: [],
  createNewPostError: {
    error: false,
    data: "",
  },
  updatePostError: {
    error: false,
    data: "",
  },
  deletePostError: {
    error: false,
    data: "",
  },
  createSucceed: false,
  updateSucceed: false,
  deleteSucceed: false,
};

export default function blogReducer(state = initialState, action: any) {
  switch (action.type) {
    case BLOG_POSTS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_BLOG_POSTS:
      return {
        ...state,
        isLoading: false,
        error: {},
        allPosts: action.payload,
      };
    case GET_BLOG_POSTS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        allPosts: [],
      };
    case CREATE_NEW_POST:
      return {
        ...state,
        createSucceed: false,
        createNewPostError: {
          error: false,
          data: "",
        },
      };
    case CREATE_NEW_POST_FAILED:
      return {
        ...state,
        createNewPostError: {
          error: true,
          data: action.payload,
        },
      };
    case CREATE_NEW_POST_SUCCEDEED:
      return {
        ...state,
        createNewPostError: {
          error: false,
          data: "",
        },
        createSucceed: true,
      };
    case UPDATE_POST:
      return {
        ...state,
        updateSucceed: false,
        updatePostError: {
          error: false,
          data: "",
        },
      };
    case UPDATE_POST_FAILED:
      return {
        ...state,
        updatePostError: {
          error: true,
          data: action.payload,
        },
      };
    case UPDATE_POST_SUCCEDEED:
      return {
        ...state,
        updatePostError: {
          error: false,
          data: "",
        },
        updateSucceed: true,
      };
    case GET_AUTHORS:
      if (Array.isArray(action.payload)) {
        return {
          ...state,
          allAuthors: action.payload,
        };
      } else {
        return {
          ...state,
          author: action.payload,
        };
      }
    case DELETE_BLOG_POST_FAILED:
      return {
        ...state,
        deletePostError: {
          error: true,
          data: action.payload,
        },
      };
    case DELETE_BLOG_POST_SUCCEEDED:
      return {
        ...state,
        deletePostError: {
          error: false,
          data: "",
        },
        deleteSucceed: true,
      };
    default:
      return state;
  }
}
