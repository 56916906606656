import { takeEvery, put } from "redux-saga/effects";
import { SET_USER, SET_USER_REQ } from "./types";

function* setUser(action: any) {
  yield put({ type: SET_USER, payload: action.payload });
}

export function* watchSetUser() {
  yield takeEvery(SET_USER_REQ, setUser);
}
