import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import "./App.scss";

import store from "./store";
import AllPosts from "./components/All Posts/AllPosts";
import { SET_USER } from "./sagas/types";
import setAuthToken from "./util/setAuthToken";
import CreatePost from "./components/Create Post/CreatePost";
import { UserContentPage } from "./components/UserContentPage";
import MyArticles from "./components/My articles/MyArticles";
import FullArticle from "./components/Full Article/FullArticle";
import NoAccess403 from "./components/NoAccess403/NoAccess403";
import AuthorPage from "./components/AuthorPage/AuthorPage";
import { getCookie } from "./util/cookieUtils";
import CookieBanner from "./components/CookieBanner";
import NotFound404 from "./components/NotFound404/NotFound404";
import { PrivateRouteOutlet } from "./components/PrivateRouteOutlet";

axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  function (response) {
    if (response.headers["newAccessToken"]) {
      let token = response.headers["newAccessToken"];
      setAuthToken(token);
      const decoded = jwt_decode(token);
      store.dispatch({ type: SET_USER, payload: decoded });
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

axios
  .get(`https://auth.joel.muehlena.de/auth/token?silent=true`)
  .then((res) => {
    if (!(res.data.msg && res.data.msg === "silentError")) {
      setAuthToken(res.data.token);
      const decoded = jwt_decode(res.data.token);
      store.dispatch({ type: SET_USER, payload: decoded });
    }
  })
  .catch((err) => {
    if (err.response) {
      store.dispatch({ type: "", payload: err.response.data });
    }
  });

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

window.addEventListener("resize", () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  (document.body as any).style.height = window.innerHeight;
});

const setCookieBanner = () => {
  let expires = new Date(new Date().getTime() + 5.184e9).toUTCString();
  document.cookie =
    "name=CookieBannerAggreement; expires=" + expires + ";path=/";

  try {
    (document.querySelector(".jm-cookieBanner")! as HTMLElement).style.display =
      "none";
  } catch (e) {}
};

export const isCookieBanner = (): boolean => {
  if (getCookie("CookieBannerAggreement")) {
    return true;
  }

  return false;
};

const App: React.FunctionComponent = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          {/* Main page route */}
          <Route
            path="/"
            element={<UserContentPage content={<AllPosts />} />}
          />

          {/* Private Routes with Authentication */}
          <Route element={<PrivateRouteOutlet />}>
            <Route path="/create" element={<CreatePost />} />
            <Route path="/my-articles" element={<MyArticles />} />
          </Route>

          {/* Non exact Routes */}
          <Route path="/author">
            <Route path=":authorId" element={<AuthorPage />} />
          </Route>

          <Route path="/article">
            <Route path=":postId" element={<FullArticle />} />
          </Route>

          {/* Error Routes */}
          <Route path="/403" element={<NoAccess403 />} />
          <Route path="/404" element={<NotFound404 />} />
        </Routes>
        {!isCookieBanner() && <CookieBanner onAccept={setCookieBanner} />}
      </BrowserRouter>
    </Provider>
  );
};

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
