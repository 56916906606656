import React, { ComponentType } from "react";
import { useRoleVerification } from "./useRoleVerification";

const withRoleVerification = <P extends object>(
  RenderComponent: ComponentType<P>,
  permission: string
) => {
  return function WrappedComponent(props: P) {
    const isVerified = useRoleVerification(permission);
    return <RenderComponent {...props} isVerified={isVerified} />;
  };
};

export default withRoleVerification;
