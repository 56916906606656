import { FunctionComponent } from "react";
import { BlogPost } from "../types/blogPost";
import { Author } from "../types/user";
import { ReactMarkdownPreDefined } from "./ReactMarkdownPreDefined";

import style from "./PostV2.module.scss";
import MongoDate from "./MongoDate";
import { isDateEqual } from "../util/isDateEqual";
import Footer from "./Layout/Footer/Footer";
import { Link } from "react-router-dom";

interface Props {
  post: BlogPost;
  author: Author;
}

export const PostV2: FunctionComponent<Props> = ({ post, author }) => {
  return (
    <div className={style.jm__blogPost}>
      <Link to={"/"} className={style.goBackButton}>
        <i className="las la-angle-left" />
        Back
      </Link>

      <div className={style.jm_blogPostHeader}>
        <h1>{post.title}</h1>
      </div>
      <div className={style.jm_blogPostSubHeader}>
        <div>
          <MongoDate date={post.createdAt} />
          {!isDateEqual(new Date(post.createdAt), new Date(post.updatedAt)) ? (
            <>
              <br />
              Updated At: <MongoDate date={post.updatedAt} />
            </>
          ) : null}
        </div>
        <div>
          <span>Category: {post.category}</span>
          <br />
          <span>Tags: {post.tags.join(", ")}</span>
        </div>
      </div>
      <div className={style.jm__blogPostContent}>
        <div className={style.jm__blogPostContentAuthorInfo}>
          <img
            src={author.avatar}
            className={style.authorImage}
            alt={`${author.firstName} ${author.lastName}`}
          />
          <span>
            <Link to={`/author/${author._id}`}>
              {author.firstName}&nbsp;{author.lastName}
            </Link>
          </span>
          <span className={style.authorBio}>
            {author.bio.length > 0 ? author.bio : "This author has no bio yet."}
          </span>
        </div>
        <ReactMarkdownPreDefined
          content={post.body}
          classNames={style.jm__blogPostContentMD}
        />
      </div>
      <Footer />
    </div>
  );
};
