//BlogPost types
export const GET_BLOG_POSTS_REQUEST = "GET_BLOG_POSTS_REQUEST";
export const GET_BLOG_POSTS = "GET_BLOG_POSTS";
export const BLOG_POSTS_LOADING = "BLOG_POSTS_LOADING";
export const GET_BLOG_POSTS_SUCCEEDED = "GET_BLOG_POSTS_SUCCEEDED";
export const GET_BLOG_POSTS_FAILED = "GET_BLOG_POSTS_FAILED";
export const DELETE_BLOG_POST_REQUEST = "DELETE_BLOG_POST_REQUEST";
export const DELETE_BLOG_POST_FAILED = "DELETE_BLOG_POST_FAILED";
export const DELETE_BLOG_POST_SUCCEEDED = "DELETE_BLOG_POST_SUCCEEDED";

//Author types
export const GET_AUTHORS_REQUEST = "GET_AUTHORS_REQUEST";
export const GET_AUTHORS = "GET_AUTHORS";
export const GET_AUTHORS_LOADING = "GET_AUTHORS_LOADING";
export const GET_AUTHORS_FAILED = "GET_AUTHORS_FAILED";
export const GET_AUTHORS_SUCCEDEED = "GET_AUTHORS_SUCCEDEED";

//User Types
export const SET_USER = "SET_USER";
export const SET_USER_LOADING = "SET_USER_LOADING";
export const SET_USER_REQ = "SET_USER_REQ";

//Create new Post types
export const CREATE_NEW_POST_REQUEST = "CREATE_NEW_POST_REQUEST";
export const CREATE_NEW_POST = "CREATE_NEW_POST";
export const CREATE_NEW_POST_SUCCEDEED = "CREATE_NEW_POST_SUCCEDEED";
export const CREATE_NEW_POST_FAILED = "CREATE_NEW_POST_FAILED";

//Update Post types
export const UPDATE_POST_REQUEST = "UPDATE_POST_REQUEST";
export const UPDATE_POST = "UPDATE_POST";
export const UPDATE_POST_SUCCEDEED = "UPDATE_POST_SUCCEDEED";
export const UPDATE_POST_FAILED = "UPDATE_POST_FAILED";

//Types for getting the filter data
export const GET_BLOG_FILTER_REQUEST = "GET_BLOG_FILTER_REQUEST";
export const FILTER_DATA_LOADING = "FILTER_DATA_LOADING";
export const FILTER_DATA = "FILTER_DATA";
export const FILTER_DATA_FAILED = "FILTER_DATA_FAILED";
