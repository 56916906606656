import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  GET_BLOG_POSTS_REQUEST,
  DELETE_BLOG_POST_REQUEST,
} from "../../sagas/types";
import { ReduxState } from "../../types/state";
import { BlogPost } from "../../types/blogPost";
import { useRoleVerification } from "../useRoleVerification";
import { Navigate, useLocation, Link } from "react-router-dom";
import BasicNavigation from "../BasicNavigation";
import FullSizeModal from "../FullSizeModal";

import style from "./MyArticles.module.scss";
import MongoDate from "../MongoDate";

const MyArticles: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const isVerified = useRoleVerification("jmBlog:post:post");
  const location = useLocation();

  const blogPosts = useSelector(
    (state: ReduxState) => state.blogPosts,
    shallowEqual
  );

  const user = useSelector((state: ReduxState) => state.user, shallowEqual);

  const [userPosts, setUserPosts] = useState<BlogPost[]>([]);

  const [modalPost, setModalPost] = useState(["", ""]);
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    dispatch({ type: GET_BLOG_POSTS_REQUEST });
  }, [dispatch]);

  useEffect(() => {
    if (
      !blogPosts.isLoading &&
      user.isAuthenticated &&
      blogPosts.allPosts.length > 0
    ) {
      let blogPostsF = blogPosts.allPosts.filter(
        (post: BlogPost) => post.authorId === user.data.id
      );

      if (blogPostsF !== undefined) {
        setUserPosts(blogPostsF);
      }
    }
  }, [blogPosts, user]);

  const deletePostRequest = (postTitle: string, postId: string) => {
    setModalPost([postTitle, postId]);
    setIsModal(true);
  };

  const abortDeletePost = () => {
    setIsModal(false);
  };

  const deletePost = () => {
    setIsModal(false);
    dispatch({ type: DELETE_BLOG_POST_REQUEST, payload: { id: modalPost[1] } });
  };

  return isVerified ? (
    <div>
      {isModal && (
        <FullSizeModal
          agreeText="Delete"
          abortText="Abort"
          title="Confirm deletion"
          content={`Are you sure you want to delete the Post "${modalPost[0]}" `}
          onAbort={abortDeletePost}
          onAgree={deletePost}
        />
      )}
      <BasicNavigation />
      {userPosts.length > 0 ? (
        <div className={style.content}>
          <div className={style.userPostWrapper}>
            {userPosts.map((post: BlogPost) => (
              <div className={style.userPost} key={post._id}>
                <div>
                  <h1>
                    {post.title}- <MongoDate date={post.createdAt} />
                  </h1>
                  <p>{post.description}</p>
                </div>
                <div className={style.postButtons}>
                  <Link
                    to={`/create?edit=true&postId=${post._id}`}
                    className={`${style.btn} ${style.btn_edit}`}
                  >
                    Edit
                  </Link>
                  <span
                    className={`${style.btn} ${style.btn_red}`}
                    onClick={() => {
                      deletePostRequest(post.title, post._id);
                    }}
                  >
                    Delete
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p className={style.noPosts}>Keine Posts</p>
      )}
    </div>
  ) : (
    <Navigate to={`/403?callback=${location.pathname}`} />
  );
};
export default MyArticles;
