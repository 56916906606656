import React, { useEffect, useRef, MutableRefObject, Fragment } from "react";
import { Link } from "react-router-dom";

import styles from "./Header.module.scss";
import { useSelector, shallowEqual } from "react-redux";
import { ReduxState } from "../../../types/state";
import { useRoleVerification } from "../../useRoleVerification";

const Header: React.FunctionComponent = () => {
  const userInfo = useSelector((state: ReduxState) => state.user, shallowEqual);
  const isVerified = useRoleVerification("jmBlog:post:post");
  const nav: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const caption: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const scrollListenerCallback = (_: any) => {
    const height = window.innerHeight - 85;

    const scrollOffset = window.pageYOffset;

    if (window.innerWidth > 600 && window.innerHeight > 625) {
      try {
        if (scrollOffset >= height * 0.8) {
          nav.current!.classList.add(styles.scrolled);
          caption.current!.classList.add(styles.hideCaption);
        } else {
          nav.current!.classList.remove(styles.scrolled);
          caption.current!.classList.remove(styles.hideCaption);
        }
      } catch (err) {}
    } else {
      try {
        nav.current!.classList.remove(styles.scrolled);
        caption.current!.classList.remove(styles.hideCaption);
      } catch (err) {}
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollListenerCallback, false);

    return () => {
      window.addEventListener("scroll", scrollListenerCallback, false);
    };
  }, []);

  return (
    <nav className={styles.navigation} ref={nav}>
      <div className={styles.leftSide}>
        <div className={styles.entry}>
          <a href="https://joel.muehlena.de">Home</a>
        </div>
        <div className={styles.entry}>
          <Link to="/">Blog</Link>
        </div>
        {userInfo.isAuthenticated && isVerified && (
          <Fragment>
            <div className={styles.entry}>
              <Link to="/my-articles">My posts</Link>
            </div>
            <div className={styles.entry}>
              <Link to="/create">New post</Link>
            </div>
          </Fragment>
        )}
      </div>
      <div className={styles.caption} ref={caption}>
        <h2 className={styles.captionHeading}>Blog</h2>
        <p className={styles.captionDescription}>
          Here is everything interesting in my life written down
        </p>
      </div>
    </nav>
  );
};

export default Header;
