import React, { FunctionComponent, MouseEvent } from "react";

interface ModalProps {
  title: string;
  content: string;
  abortText: string;
  agreeText: string;
  onAbort: (e: MouseEvent<HTMLButtonElement>) => void;
  onAgree: (e: MouseEvent<HTMLButtonElement>) => void;
}

const FullSizeModal: FunctionComponent<ModalProps> = ({
  content,
  onAbort,
  onAgree,
  title,
  abortText,
  agreeText,
}) => {
  return (
    <div className="u-fullSizeModal">
      <div className="u-fullSizeModal__wrapper">
        <h2>{title}</h2>
        <p>{content}</p>
        <button onClick={onAgree}>{agreeText}</button>
        <button onClick={onAbort}>{abortText}</button>
      </div>
    </div>
  );
};

export default FullSizeModal;
