import React from "react";
import { BlogPost } from "../types/blogPost";
import MongoDate from "./MongoDate";
import { isDateEqual } from "../util/isDateEqual";
import { useNavigate } from "react-router-dom";

import styles from "./Post.module.scss";
import { shallowEqual, useSelector } from "react-redux";
import { ReduxState } from "../types/state";

const PostPreview: React.FunctionComponent<{ post: BlogPost }> = ({ post }) => {
  const authors = useSelector(
    (state: ReduxState) => state.blogAuthors,
    shallowEqual
  );

  const navigate = useNavigate();

  const openPost = () => {
    navigate(`/article/${post._id}`);
  };

  return (
    <div className={styles.blogPostPreview} onClick={openPost}>
      <p className={styles.category}>{post.category}</p>
      <h2 className={styles.title}>{post.title}</h2>
      <p className={styles.description}>{post.description}</p>
      <p className={styles.authorName}>
        by {post.authorName}
        {authors.allAuthors.length > 0 && (
          <img
            src={
              authors.allAuthors.find((a) => post.authorId === a._id)?.avatar
            }
            height="100"
            className={styles.authorAvatar}
            alt={`Avatar of ${post.authorName}`}
          />
        )}
      </p>
      <p className={styles.dateContainer}>
        {!isDateEqual(new Date(post.createdAt), new Date(post.updatedAt)) ? (
          <MongoDate date={post.updatedAt} />
        ) : (
          <MongoDate date={post.createdAt} />
        )}
      </p>
      <div className={styles.tagBox}>
        {post.tags.map((tag, i) => (
          <div key={i}>{tag}</div>
        ))}
      </div>
    </div>
  );
};

export default PostPreview;
