import axios from "axios";
import {
  GET_BLOG_POSTS,
  GET_BLOG_POSTS_REQUEST,
  GET_BLOG_FILTER_REQUEST,
  GET_BLOG_POSTS_SUCCEEDED,
  GET_BLOG_POSTS_FAILED,
  FILTER_DATA_FAILED,
  FILTER_DATA_LOADING,
  FILTER_DATA,
  CREATE_NEW_POST_REQUEST,
  CREATE_NEW_POST_FAILED,
  CREATE_NEW_POST_SUCCEDEED,
  GET_AUTHORS,
  GET_AUTHORS_FAILED,
  GET_AUTHORS_LOADING,
  GET_AUTHORS_REQUEST,
  GET_AUTHORS_SUCCEDEED,
  BLOG_POSTS_LOADING,
  DELETE_BLOG_POST_REQUEST,
  DELETE_BLOG_POST_FAILED,
  DELETE_BLOG_POST_SUCCEEDED,
  UPDATE_POST_REQUEST,
  UPDATE_POST_SUCCEDEED,
  UPDATE_POST_FAILED,
} from "./types";
import {
  takeLatest,
  takeEvery,
  put,
  call,
  StrictEffect,
} from "redux-saga/effects";
import { NewPostPayload, UpdatePostPayload } from "../types/crud";
import { BlogPost } from "../types/blogPost";
import { Author } from "../types/user";

const API_URL = "https://joel.muehlena.de/api";
const API_GATEWAY_URL = "https://api.joel.muehlena.de/v1/blog";

function* fetchBlogPost(action: {
  type: string;
  payload: any;
}): Generator<StrictEffect, void, { data: BlogPost[] }> {
  let queryString = "";
  if (action.payload?.filter) {
    if (!action.payload.limit) queryString += "?";

    for (let [key, value] of Object.entries(action.payload.filter)) {
      if (queryString.length === 1) {
        queryString += `${key}=${value}`;
      } else {
        queryString += `&${key}=${value}`;
      }
    }
  }

  try {
    yield put({ type: BLOG_POSTS_LOADING });

    const posts = yield call(
      axios.get,
      `${API_URL}/blog/posts${
        action.payload?.limit ? "?limit=" + action.payload.limit : ""
      }${queryString}`
    );

    yield put({
      type: GET_BLOG_POSTS,
      payload: posts.data,
    });

    yield put({
      type: GET_BLOG_POSTS_SUCCEEDED,
    });
  } catch (error: any) {
    if (error.response) {
      yield put({
        type: GET_BLOG_POSTS_FAILED,
        payload: error.response.data,
      });
    }
  }
}

function* fetchFilterData(): Generator<StrictEffect, void, { data: any }> {
  try {
    yield put({ type: FILTER_DATA_LOADING, payload: true });
    const filterData = yield call(axios.get, `${API_URL}/blog/filterOptions`);
    yield put({ type: FILTER_DATA, payload: filterData.data });
  } catch (error: any) {
    if (error.response) {
      yield put({ type: FILTER_DATA_FAILED, payload: error.response.data });
    }
  }
}

function* createNewBlogPost(action: { type: string; payload: NewPostPayload }) {
  try {
    yield call(axios.post, `${API_URL}/blog`, action.payload);
    yield put({ type: GET_BLOG_POSTS_REQUEST });
    yield put({ type: CREATE_NEW_POST_SUCCEDEED });
  } catch (error: any) {
    if (error.response) {
      yield put({ type: CREATE_NEW_POST_FAILED, payload: error.response.data });
    }
  }
}

function* updateBlogPost(action: { type: string; payload: UpdatePostPayload }) {
  try {
    yield call(
      axios.put,
      `${API_URL}/blog/${action.payload.postId}`,
      action.payload
    );
    yield put({ type: GET_BLOG_POSTS_REQUEST });
    yield put({ type: UPDATE_POST_SUCCEDEED });
  } catch (error: any) {
    if (error.response) {
      yield put({ type: UPDATE_POST_FAILED, payload: error.response.data });
    }
  }
}

function* fetchAuthors(action: {
  type: string;
  payload: { id?: string };
}): Generator<StrictEffect, void, { data: { data: Author[] } }> {
  try {
    yield put({ type: GET_AUTHORS_LOADING, payload: true });
    if (action.payload && action.payload.id) {
      const authors = yield call(
        axios.get,
        `${API_GATEWAY_URL}/author/${action.payload.id}/`
      );
      yield put({ type: GET_AUTHORS, payload: authors.data.data });
    } else {
      const authors = yield call(axios.get, `${API_GATEWAY_URL}/author/`);
      yield put({ type: GET_AUTHORS, payload: authors.data.data });
    }

    yield put({ type: GET_AUTHORS_SUCCEDEED });
  } catch (error: any) {
    if (error.response) {
      yield put({ type: GET_AUTHORS_FAILED, payload: error.response.data });
    }
  }
}

function* deleteBlogPost(action: { type: string; payload: { id: string } }) {
  try {
    yield call(axios.delete, `${API_URL}/blog/${action.payload.id}`);
    yield put({ type: DELETE_BLOG_POST_SUCCEEDED });
    yield put({ type: GET_BLOG_POSTS_REQUEST });
  } catch (error: any) {
    if (error.response) {
      yield put({
        type: DELETE_BLOG_POST_FAILED,
        payload: error.response.data,
      });
    }
  }
}

export function* watchAuthorFetch() {
  yield takeEvery(GET_AUTHORS_REQUEST, fetchAuthors);
}

export function* watchCreateNewPost() {
  yield takeEvery(CREATE_NEW_POST_REQUEST, createNewBlogPost);
}

export function* watchUpdatePost() {
  yield takeEvery(UPDATE_POST_REQUEST, updateBlogPost);
}

export function* watchPostFetch() {
  yield takeLatest(GET_BLOG_POSTS_REQUEST, fetchBlogPost);
}

export function* watchFilterFetch() {
  yield takeLatest(GET_BLOG_FILTER_REQUEST, fetchFilterData);
}

export function* watchDeletePost() {
  yield takeEvery(DELETE_BLOG_POST_REQUEST, deleteBlogPost);
}
