import React from "react";
import { Link } from "react-router-dom";

const CookieBanner: React.FunctionComponent<{ onAccept: () => void }> = ({
  onAccept,
}) => {
  return (
    <div className="jm-cookieBanner">
      <div>
        This site is using cookies. <Link to="/cookies">More info</Link>
      </div>
      <div>
        <button onClick={onAccept}>Accept</button>
      </div>
    </div>
  );
};

export default CookieBanner;
