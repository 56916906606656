import {
  GET_AUTHORS,
  GET_AUTHORS_LOADING,
  GET_AUTHORS_FAILED,
} from "../sagas/types";

const initialState = {
  isLoading: false,
  error: {},
  author: {},
  allAuthors: [],
};

export function authorReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_AUTHORS:
      if (Array.isArray(action.payload)) {
        return {
          ...state,
          isLoading: false,
          allAuthors: action.payload,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          author: action.payload,
        };
      }
    case GET_AUTHORS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case GET_AUTHORS_FAILED:
      return {
        ...state,
        author: {},
        allAuthors: [],
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
