import React, { ChangeEvent, MouseEvent, FormEvent, Component } from "react";
import { Base64 } from "js-base64";

import styles from "./CreatePost.module.scss";
import FullSizeModal from "../FullSizeModal";
import {
  CREATE_NEW_POST_REQUEST,
  GET_BLOG_FILTER_REQUEST,
  GET_BLOG_POSTS_REQUEST,
  UPDATE_POST_REQUEST,
} from "../../sagas/types";
import { connect } from "react-redux";
import { ReduxState } from "../../types/state";
import { NewPostPayload, UpdatePostPayload } from "../../types/crud";
import withRoleVerification from "../withRoleVerification";
import { RoleVerificationProps } from "../../types/hoc";
import { withRouter, WithRouterProps } from "../withRouter";
import { ReactMarkdownPreDefined } from "../ReactMarkdownPreDefined";

interface MapDispatchToProps {
  getFilterOptions: () => void;
  getBlogPosts: () => void;
  createNewPost: (payload: NewPostPayload) => void;
  updatePost: (payload: UpdatePostPayload) => void;
}
interface DefaultState {
  isEditMode: boolean;
  isEditDataAppylied: boolean;
  postId: string;
  isPreview: boolean;
  isCancel: boolean;
  title: string;
  description: string;
  postContent: string;
  category: string;
  tags: string;
  isNewCategory: boolean;
  isSuccedeed: boolean;
  isFailed: boolean;
  failedData: string;
}

type Props = ReduxState &
  MapDispatchToProps &
  RoleVerificationProps &
  WithRouterProps;

class CreatePost extends Component<Props, DefaultState> {
  state = {
    isEditMode: false,
    isEditDataAppylied: false,
    postId: "",
    isPreview: false,
    isCancel: false,
    postContent: "",
    title: "",
    description: "",
    category: "",
    tags: "",
    isNewCategory: false,
    isSuccedeed: false,
    isFailed: false,
    failedData: "",
  };

  componentDidMount() {
    this.props.getFilterOptions();

    let edit = new URLSearchParams(this.props.location.search).get("edit");

    if (edit !== null) {
      let postId = new URLSearchParams(this.props.location.search).get(
        "postId"
      );
      if (postId !== null) {
        this.setState((_) => ({ isEditMode: true, postId: postId! }));
        this.props.getBlogPosts();
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.blogPosts.createSucceed !== this.props.blogPosts.createSucceed
    ) {
      if (this.props.blogPosts.createSucceed) {
        this.setState({
          isSuccedeed: true,
          isPreview: false,
          postContent: "",
          category: "",
          title: "",
          description: "",
          tags: "",
          isNewCategory: false,
          failedData: "",
          isFailed: false,
        });
      }
    }

    if (
      prevProps.blogPosts.updateSucceed !== this.props.blogPosts.updateSucceed
    ) {
      if (this.props.blogPosts.updateSucceed) {
        this.setState({
          isSuccedeed: true,
          isPreview: false,
          postContent: "",
          category: "0",
          title: "",
          description: "",
          tags: "",
          isNewCategory: false,
          failedData: "",
          isFailed: false,
          isEditMode: false,
          isEditDataAppylied: false,
          postId: "",
        });

        this.props.navigate("/create");
      }
    }

    if (
      prevProps.blogPosts.createNewPostError.state !==
      this.props.blogPosts.createNewPostError.state
    ) {
      if (this.props.blogPosts.createNewPostError.state) {
        this.setState({
          isFailed: true,
          failedData: this.props.blogPosts.createNewPostError.data,
        });
      }
    }

    if (prevProps.isVerified !== this.props.isVerified) {
      if (!this.props.isVerified) {
        this.props.navigate(
          `/403?callback=${Base64.encode(
            this.props.location.pathname + this.props.location.search
          )}`
        );
      }
    }

    if (
      this.props.blogPosts.allPosts.length > 0 &&
      this.state.isEditMode &&
      !this.state.isEditDataAppylied
    ) {
      let posts = this.props.blogPosts.allPosts;
      let postToEdit = posts.find((post) => post._id === this.state.postId);

      if (postToEdit !== undefined) {
        let isCreatorUser = this.props.user.data.id === postToEdit.authorId;

        if (!isCreatorUser) {
          this.props.navigate(
            `/403?callback=${Base64.encode(
              this.props.location.pathname + this.props.location.search
            )}&message=${Base64.encode("You aren't the author of this post")}`
          );
        }

        this.setState((_) => ({
          title: postToEdit!.title,
          description: postToEdit!.description,
          postContent: postToEdit!.body,
          category: postToEdit!.category,
          tags: postToEdit!.tags.join(","),
          isEditDataAppylied: true,
        }));
      } else {
        this.props.navigate(
          `/404?message=${Base64.encode(
            "This post doesn't exist. Please go to My articles and use the Edit button"
          )}`
        );
      }
    }
  }

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    const {
      postContent,
      title,
      description,
      category,
      tags,
      isEditMode,
      postId,
    } = this.state;
    const { user } = this.props;
    e.preventDefault();
    if (!isEditMode) {
      let payload: NewPostPayload = {
        body: postContent,
        title,
        description,
        author: user.data.firstName + " " + user.data.lastName,
        id: user.data.id,
        category,
        tags,
      };
      this.props.createNewPost(payload);
    } else {
      let payload: UpdatePostPayload = {
        body: postContent,
        title,
        description,
        category,
        tags,
        postId,
      };
      this.props.updatePost(payload);
    }
  };

  handleReset = (e: FormEvent<HTMLFormElement>) => {
    this.setState({ isCancel: true });
  };

  handleSetPreview = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.setState((state) => {
      return {
        isPreview: !state.isPreview,
      };
    });
  };

  changeContent = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ postContent: e.currentTarget.value });
  };

  changeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ title: e.currentTarget.value });
  };

  changeDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ description: e.currentTarget.value });
  };

  changeCategory = (e: ChangeEvent<HTMLSelectElement>) => {
    const { isNewCategory } = this.state;
    const value = e.currentTarget.value;

    if (value === "newCategory") {
      this.setState({ isNewCategory: true, category: "" });
    } else {
      if (isNewCategory) {
        this.setState({ isNewCategory: false });
      }
      this.setState({ category: value });
    }
  };

  modalAgree = (_: MouseEvent<HTMLButtonElement>) => {
    this.props.navigate(-1);
  };

  modalAbort = (_: MouseEvent<HTMLButtonElement>) => {
    this.setState({ isCancel: false });
  };

  changeNewCategory = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ category: e.currentTarget.value });
  };

  changeTags = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ tags: e.currentTarget.value });
  };

  render() {
    const {
      isCancel,
      isNewCategory,
      isPreview,
      category,
      postContent,
      title,
      description,
      tags,
      isFailed,
      isSuccedeed,
      failedData,
      isEditMode,
    } = this.state;

    const { filterOptions } = this.props;

    return (
      <div className={styles.createPost}>
        {isCancel && (
          <FullSizeModal
            agreeText="Stay"
            abortText="Leave"
            content="Are you sure you want to leave this page? All data will be deleted!"
            onAgree={this.modalAbort}
            onAbort={this.modalAgree}
            title="Leave this page?"
          />
        )}

        {isSuccedeed && (
          <h2 className={styles.postPublish_success}>Post published</h2>
        )}
        {isFailed && (
          <h2 className={styles.postPublish_error}>Error: {failedData}</h2>
        )}
        <h2 className={styles.heading}>
          {isEditMode ? "Update Post" : "Create a new Post"}
        </h2>
        <form
          onReset={this.handleReset}
          onSubmit={this.handleSubmit}
          className={styles.newPostForm}
        >
          <div className={styles.formGroup}>
            {isPreview ? (
              <ReactMarkdownPreDefined
                content={`# ${title}\n\n${
                  description.length > 0 ? "*" + description + "*" : ""
                }\n\n${postContent}`}
                classNames={styles.markdownPreview}
              />
            ) : (
              <>
                <div className={styles.formGroup}>
                  <input
                    type="text"
                    name="title"
                    value={title}
                    onChange={this.changeTitle}
                    placeholder="Title"
                  />
                </div>
                <div className={styles.formGroup}>
                  <textarea
                    name="description"
                    value={description}
                    className={styles.description}
                    placeholder="Enter short description (max. 300 words)"
                    onChange={this.changeDescription}
                  />
                </div>
                <div className={styles.formGroup}>
                  <textarea
                    className={styles.postContent}
                    name="postContent"
                    onChange={this.changeContent}
                    placeholder="Here is the space for all your content"
                    value={postContent}
                  />
                  <small>You can use Markdown</small>
                </div>
              </>
            )}
            <button
              onClick={this.handleSetPreview}
              className={styles.btn_preview}
            >
              {isPreview ? "Edit" : "Preview"}
            </button>
          </div>
          <div className={styles.formGroup}>
            {/*<label htmlFor="category">Category</label>*/}
            <select
              name="category"
              onChange={this.changeCategory}
              defaultValue="0"
            >
              <option value="0">Select or add a category</option>
              {filterOptions !== undefined &&
              filterOptions.data.categories !== undefined
                ? filterOptions.data.categories.map(
                    (option: string, i: number) => {
                      if (category === option) {
                        return (
                          <option key={i} value={option} selected>
                            {option}
                          </option>
                        );
                      } else {
                        return (
                          <option key={i} value={option}>
                            {option}
                          </option>
                        );
                      }
                    }
                  )
                : null}
              <option value="newCategory">new category</option>
            </select>
            {isNewCategory ? (
              <input
                type="text"
                name="newCategory"
                value={category}
                onChange={this.changeNewCategory}
              />
            ) : null}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="tags">Tags</label>
            <input
              type="text"
              name="tags"
              value={tags}
              onChange={this.changeTags}
            />
            <small>Seperate the tags using a comma</small>
          </div>
          <div className={`${styles.formGroup} ${styles.btnGroup}`}>
            <button type="submit">{isEditMode ? "Edit" : "Post"}</button>
            <button type="reset">Cancel</button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    filterOptions: state.filterOptions,
    blogPosts: state.blogPosts,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getFilterOptions: () => dispatch({ type: GET_BLOG_FILTER_REQUEST }),
    getBlogPosts: () => dispatch({ type: GET_BLOG_POSTS_REQUEST }),
    createNewPost: (payload: NewPostPayload) =>
      dispatch({ type: CREATE_NEW_POST_REQUEST, payload }),
    updatePost: (payload: UpdatePostPayload) =>
      dispatch({ type: UPDATE_POST_REQUEST, payload }),
  };
};

export default withRoleVerification(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePost)),
  "jmBlog:post:post"
);
