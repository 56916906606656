import React, { Dispatch, SetStateAction, MouseEvent } from "react";

const Filter: React.FunctionComponent<{
  single?: boolean;
  setFilter: Dispatch<SetStateAction<boolean>>;
}> = ({ single, setFilter }) => {
  const toggleFilterMenu = (e: MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget.classList.contains("filterButton--opened")) {
      setFilter(false);
      document.getElementsByTagName("body")[0]!.style.overflowY = "visible";
      e.currentTarget.classList.remove("filterButton--opened");
    } else {
      setFilter(true);
      document.getElementsByTagName("body")[0]!.style.overflow = "hidden";
      e.currentTarget.classList.add("filterButton--opened");
    }
  };

  return (
    <div
      className={`filterButton btn-round`}
      data-single={single}
      onClick={toggleFilterMenu}
    >
      <i className="las la-filter"></i>
      <i className="las la-times"></i>
    </div>
  );
};

export default Filter;
