import React, { useEffect, useRef } from "react";
import { Base64 } from "js-base64";
import { Outlet } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { ReduxState } from "../types/state";

const navigateToLogin = (callbackUrl: string) => {
  window.location.href = `https://joel.muehlena.de/login?callbackUrl=${callbackUrl}`;
};

export const PrivateRouteOutlet = () => {
  const callbackUrlEncoded = useRef<string>(
    Base64.encode(window.location.href)
  );
  const { isAuthenticated: isAuth, isLoading: isUserLoading } = useSelector(
    (state: ReduxState) => state.user,
    shallowEqual
  );

  useEffect(() => {
    if (!isAuth && !isUserLoading) navigateToLogin(callbackUrlEncoded.current);
  }, [isAuth, isUserLoading]);

  return isAuth ? <Outlet /> : <></>;
};
