import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { GET_BLOG_POSTS_REQUEST, GET_AUTHORS_REQUEST } from "../../sagas/types";
import { useParams, useNavigate } from "react-router-dom";
import { Base64 } from "js-base64";

import { ReduxState } from "../../types/state";
import { BlogPost } from "../../types/blogPost";
import { Author } from "../../types/user";
import isEmpty from "../../util/isEmpty";
import { PostV2 } from "../PostV2";

const FullArticle: React.FunctionComponent = () => {
  const posts = useSelector(
    (state: ReduxState) => state.blogPosts.allPosts,
    shallowEqual
  );

  const authors = useSelector(
    (state: ReduxState) => state.blogAuthors,
    shallowEqual
  );

  const [post, setPost] = useState(undefined);
  const [author, setAuthor] = useState(undefined);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { postId } = useParams();

  useEffect(() => {
    dispatch({ type: GET_BLOG_POSTS_REQUEST });
  }, [dispatch]);

  useEffect(() => {
    if (post !== undefined) {
      let authorF = authors.allAuthors.find(
        (a: Author) => a._id === (post! as BlogPost).authorId
      );

      if (!isEmpty(authors.author)) {
        setAuthor(!authors.isLoading ? (authors.author as any) : undefined);
      } else {
        if (authorF === undefined) {
          dispatch({
            type: GET_AUTHORS_REQUEST,
            payload: { id: (post! as BlogPost).authorId },
          });
        } else {
          setAuthor(!authors.isLoading ? (authorF as any) : undefined);
        }
      }
    }
  }, [dispatch, post, authors, author]);

  useEffect(() => {
    let post = posts.find((p: BlogPost) => p._id === postId);
    setPost(post !== undefined ? (post as any) : undefined);

    if (posts.length > 0 && post === undefined) {
      navigate(`/404?message=${Base64.encode("No post found")}`);
    }
  }, [posts, postId, navigate]);

  return (
    <>
      {post !== undefined && author !== undefined && (
        <PostV2 post={post} author={author} />
      )}
    </>
  );
};

export default FullArticle;
