export const setCookie = (name: string, value: string, days: number) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};
export const getCookie = (name: string) => {
  var nameEQ = "name=" + name;
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    if (c.indexOf(nameEQ) !== -1) {
      return true;
    }
  }
  return null;
};
export const eraseCookie = (name: string) => {
  document.cookie = name + "=; Max-Age=-99999999;";
};
