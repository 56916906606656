export const isDateEqual = (d1: Date, d2: Date): boolean => {
  if (
    d1.getDate() === d2.getDate() &&
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth()
  ) {
    return true;
  }

  return false;
};
